import { ticketConstants } from '../constants/ticket.constants';
import ticketService from '../services/ticket.service';
import { alertActions } from './alert.actions';

export const ticketActions = {
  create,
  createInteraction,
  update,
  getAll,
  getById,
  uploadAttachment,
  deleteAttachmentById,
  getAllInteraction,
  deleteById,
  getStatusTickets,
};

function create(obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const ticket = await ticketService.create(obj);

      dispatch(success(ticket));
      dispatch(alertActions.success('Ticket registrado', true));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(`Falha na abertura do ticket. ${error}`, true));
      throw error;
    }
  };

  function request() {
    return { type: ticketConstants.CREATETICKET_REQUEST };
  }
  function success(ticket) {
    return { type: ticketConstants.CREATETICKET_SUCCESS, ticket };
  }
  function failure(error) {
    return { type: ticketConstants.CREATETICKET_FAILURE, error };
  }
}

function uploadAttachment(id_ticket, data) {
  return async (dispatch) => {
    dispatch(request(data));

    try {
      const attachment = await ticketService.uploadAttachment(id_ticket, data);

      dispatch(success(attachment));
      dispatch(alertActions.success('Arquivo enviado', true));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(attachment) {
    return { type: ticketConstants.CREATEATTACHMENT_REQUEST, attachment };
  }
  function success(attachment) {
    return { type: ticketConstants.CREATEATTACHMENT_SUCCESS, attachment };
  }
  function failure(error) {
    return { type: ticketConstants.CREATEATTACHMENT_FAILURE, error };
  }
}

function deleteAttachmentById(id_ticket, id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const attachment = await ticketService.deleteAttachmentById(id_ticket, id);

      dispatch(success(attachment));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(attachment) {
    return { type: ticketConstants.DELETEATTACHMENT_REQUEST, attachment };
  }
  function success(attachment) {
    return { type: ticketConstants.DELETEATTACHMENT_SUCCESS, attachment };
  }
  function failure(error) {
    return { type: ticketConstants.DELETEATTACHMENT_FAILURE, error };
  }
}

function createInteraction(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const interaction = await ticketService.createInteraction(id, obj);

      dispatch(success(interaction));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: ticketConstants.CREATETICKETINTERACTION_REQUEST };
  }
  function success(interaction) {
    return {
      type: ticketConstants.CREATETICKETINTERACTION_SUCCESS,
      interaction,
    };
  }
  function failure(error) {
    return { type: ticketConstants.CREATETICKETINTERACTION_FAILURE, error };
  }
}

function update(id, obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const ticket = await ticketService.update(id, obj);

      dispatch(success(ticket));
      this.props.history.push('/tickets');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: ticketConstants.UPDATETICKET_REQUEST };
  }
  function success(ticket) {
    return { type: ticketConstants.UPDATETICKET_SUCCESS, ticket };
  }
  function failure(error) {
    return { type: ticketConstants.UPDATETICKET_FAILURE, error };
  }
}

function getAll(filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const tickets = await ticketService.getAll(filter);

      dispatch(success(tickets));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: ticketConstants.GETALLTICKET_REQUEST };
  }
  function success(tickets) {
    return { type: ticketConstants.GETALLTICKET_SUCCESS, tickets };
  }
  function failure(error) {
    return { type: ticketConstants.GETALLTICKET_FAILURE, error };
  }
}

function getAllInteraction(id, filter) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const interactions = await ticketService.getAllInteraction(id, filter);

      dispatch(success(interactions));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: ticketConstants.GETALLTICKETINTERACTION_REQUEST };
  }
  function success(interactions) {
    return {
      type: ticketConstants.GETALLTICKETINTERACTION_SUCCESS,
      interactions,
    };
  }
  function failure(error) {
    return { type: ticketConstants.GETALLTICKETINTERACTION_FAILURE, error };
  }
}

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const ticket = await ticketService.getById(id);

      dispatch(success(ticket));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(ticket) {
    return { type: ticketConstants.GETTICKET_REQUEST, ticket };
  }
  function success(ticket) {
    return { type: ticketConstants.GETTICKET_SUCCESS, ticket };
  }
  function failure(error) {
    return { type: ticketConstants.GETTICKET_FAILURE, error };
  }
}

function getStatusTickets() {
  return async (dispatch) => {
    dispatch(request());

    try {
      const status = await ticketService.getStatusTickets();

      dispatch(success(status));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: ticketConstants.GETSTATUS_REQUEST };
  }
  function success(status) {
    return { type: ticketConstants.GETSTATUS_SUCCESS, status };
  }
  function failure(error) {
    return { type: ticketConstants.GETSTATUS_FAILURE, error };
  }
}

function deleteById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const ticket = await ticketService.deleteById(id);

      dispatch(success(ticket));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(ticket) {
    return { type: ticketConstants.DELETETICKET_REQUEST, ticket };
  }
  function success(ticket) {
    return { type: ticketConstants.DELETETICKET_SUCCESS, ticket };
  }
  function failure(error) {
    return { type: ticketConstants.DELETETICKET_FAILURE, error };
  }
}
