export const userConstants = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  GETALLUSER_REQUEST: 'USERS_GETALLUSER_REQUEST',
  GETALLUSER_SUCCESS: 'USERS_GETALLUSER_SUCCESS',
  GETALLUSER_FAILURE: 'USERS_GETALLUSER_FAILURE',

  GETUSER_REQUEST: 'GETUSER_REQUEST',
  GETUSER_SUCCESS: 'GETUSER_SUCCESS',
  GETUSER_FAILURE: 'GETUSER_FAILURE',

  GETUSERME_REQUEST: 'GETUSERME_REQUEST',
  GETUSERME_SUCCESS: 'GETUSERME_SUCCESS',
  GETUSERME_FAILURE: 'GETUSERME_FAILURE',

  CREATEUSER_REQUEST: 'CREATEUSER_REQUEST',
  CREATEUSER_SUCCESS: 'CREATEUSER_SUCCESS',
  CREATEUSER_FAILURE: 'CREATEUSER_FAILURE',

  FORGOTUSER_REQUEST: 'FORGOTUSER_REQUEST',
  FORGOTUSER_SUCCESS: 'FORGOTUSER_SUCCESS',
  FORGOTUSER_FAILURE: 'FORGOTUSER_FAILURE',

  RESETUSER_REQUEST: 'RESETUSER_REQUEST',
  RESETUSER_SUCCESS: 'RESETUSER_SUCCESS',
  RESETUSER_FAILURE: 'RESETUSER_FAILURE',

  UPDATEUSER_REQUEST: 'UPDATEUSER_REQUEST',
  UPDATEUSER_SUCCESS: 'UPDATEUSER_SUCCESS',
  UPDATEUSER_FAILURE: 'UPDATEUSER_FAILURE',

  UPDATEUSERME_REQUEST: 'UPDATEUSERME_REQUEST',
  UPDATEUSERME_SUCCESS: 'UPDATEUSERME_SUCCESS',
  UPDATEUSERME_FAILURE: 'UPDATEUSERME_FAILURE',

  GETALLROLES_REQUEST: 'USERS_GETALLROLES_REQUEST',
  GETALLROLES_SUCCESS: 'USERS_GETALLROLES_SUCCESS',
  GETALLROLES_FAILURE: 'USERS_GETALLROLES_FAILURE',
};
