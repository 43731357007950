import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon, Pagination, Table } from 'semantic-ui-react';
import _ from 'lodash';

class DynamicTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      column: null,
      columns: [],
      search: [],
      direction: null,
      data: [],
      header: [],
      totalPages: 1,
    };
  }

  componentDidMount() {
    this.setState({ ...this.props, filteredData: this.props.data });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.page !== this.props.page || prevProps.data.length != this.props.data.length) {
      this.setState({ ...this.props, filteredData: this.props.data });
    }
  }

  handleSearch(value) {
    const { data, search_per } = this.state;

    if (!search_per) return;

    let newData = data.filter((item) => {
      if (item[search_per]) {
        return item[search_per].toLowerCase().indexOf(value.toLowerCase()) != -1;
      }
    });

    this.setState({
      search_value: value,
      filteredData: newData,
    });
  }

  handleSort = (clickedColumn) => () => {
    const { data, direction } = this.state;
    let column = typeof clickedColumn == 'object' ? clickedColumn.name : clickedColumn;

    this.setState({
      column: column,
      filteredData: _.orderBy(data, [column], direction == 'ascending' ? 'desc' : 'asc'),
      direction: direction == 'ascending' ? 'descending' : 'ascending',
    });
  };

  validRow(data, header) {
    if (!data || !data.length) {
      return (
        <Table.Row key={0}>
          <Table.Cell colSpan={header.length} textAlign="center">
            Nenhum Resultado Encontrado
          </Table.Cell>
        </Table.Row>
      );
    }
  }

  transformResult(item, value) {
    switch (item.type) {
      case 'boolean':
        return value ? 'Ativo' : 'Inativo';
      default:
        return value;
    }
  }

  cellContent(data) {
    const { columns } = this.state;
    return _.map(columns, (item, index) => {
      let value = data[item];

      if (typeof item === 'object' && typeof item.format == 'function') {
        value = item.format(data);
      } else if (typeof item === 'object') {
        value = this.transformResult(item, data[item.name]);
      }
      return <Table.Cell key={index}>{value}</Table.Cell>;
    });
  }

  onClick(item) {
    this.props.onClick(item);
  }

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ page: activePage });
    if (this.props.handlePaginationChange) {
      this.props.handlePaginationChange(activePage);
    }
  };

  render() {
    const { column, columns, filteredData, header, direction, page, totalPages } = this.state;

    return (
      <Fragment>
        <Table celled sortable padded basic="very">
          <Table.Header>
            <Table.Row>
              {_.map(header, (item, key) => (
                <Table.HeaderCell
                  key={key}
                  sorted={column === columns[key] ? direction : null}
                  onClick={this.handleSort(columns[key])}>
                  {item}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(filteredData, (item, key) => (
              <Table.Row key={key} onClick={() => this.onClick(item)} className="item-link">
                {this.cellContent(item)}
              </Table.Row>
            ))}
            {this.validRow(filteredData, header)}
          </Table.Body>
        </Table>
        <div style={{ margin: '0 auto', textAlign: 'center' }}>
          <Pagination
            activePage={page}
            ellipsisItem={null}
            onPageChange={this.handlePaginationChange}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true,
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true,
            }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
            totalPages={totalPages}
          />
        </div>
      </Fragment>
    );
  }
}

DynamicTable.propTypes = {
  data: PropTypes.array.isRequired,
  header: PropTypes.array.isRequired,
  handlePaginationChange: PropTypes.func.isRequired,
  search: PropTypes.object,
  columns: PropTypes.array.isRequired,
};

export { DynamicTable };
