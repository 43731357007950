import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { SemanticToastContainer, toast } from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';

import { MenuBand } from '../components/MenuBand/MenuBand';
import { withRouter } from 'react-router-dom';

import './styles.css';
import './colors.css';

import { alertActions } from '../actions/alert.actions';
import { companyActions } from '../actions/company.actions';

import { ProfileScreen } from '../screens/Profile';

import { TicketScreen } from '../screens/Ticket';
import { TicketCreateScreen } from '../screens/Ticket/create';
import { TicketShowScreen } from '../screens/Ticket/show';

import { LoginScreen } from '../screens/Login';
import { RecoverPasswordScreen } from '../screens/RecoverPassword';
import { ResetPasswordScreen } from '../screens/RecoverPassword/ResetPassword';
import FailedMessage from '../screens/FailedMessage';

import PropTypes from 'prop-types';

class App extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;
    dispatch(companyActions.getById(1)).then(() => {
      let { company } = this.props;

      if (company) {
        localStorage.setItem('company', JSON.stringify(company));
      }
    });

    this.props.history.listen(() => {
      dispatch(alertActions.clear());
    });
  }

  getCompany() {
    const data = localStorage.getItem('company');
    return data ? JSON.parse(data) : null;
  }

  customTheme() {
    let { company } = this.props;

    if (!company) return null;

    const content = (
      <style type="text/css">
        :root {'{'}
        --primary: {company.primary_color}; --secondary: {company.secondary_color}; --tertiary:{' '}
        {company.tertiary_color};{'}'}
      </style>
    );

    return content;
  }

  customTitle() {
    const company = this.getCompany();

    if (!company) return null;

    return <title>{company.title}</title>;
  }

  render() {
    const { alert } = this.props;
    if (alert.message && alert.show !== false) {
      toast({
        type: alert.type,
        icon: 'info',
        description: alert.message,
        time: 50000,
        onClose: () => {
          const { dispatch } = this.props;

          dispatch(alertActions.clear());
        },
      });
    }

    return (
      <Fragment>
        {this.customTheme()}
        {this.customTitle()}
        <SemanticToastContainer position="bottom-right" />
        <Switch>
          <Route exact path="/login" component={LoginScreen} />
          <Route exact path="/404" component={FailedMessage} />
          <Route exact path="/recover-password" component={RecoverPasswordScreen} />
          <Route exact path="/password/reset/:hash" component={ResetPasswordScreen} />
          <MenuBand>
            <div className="main-container">
              <div className="wrapper">
                <Route exact path="/" component={TicketScreen} />
                <Route exact path="/profile" component={ProfileScreen} />
                <Route exact path="/tickets" component={TicketScreen} />
                <Route exact path="/tickets/create" component={TicketCreateScreen} />
                <Route path="/tickets/:id/show" component={TicketShowScreen} />
              </div>
            </div>
          </MenuBand>
        </Switch>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { alert, companies } = state;
  return {
    company: companies.item || null,
    alert,
  };
}

App.propTypes = {
  dispatch: PropTypes.func,
  company: PropTypes.any,
  alert: PropTypes.any,
};

const connectedApp = withRouter(connect(mapStateToProps)(App));
export { connectedApp as App };
