import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Grid, Header, Image, Label } from 'semantic-ui-react';
import { userActions } from '../../actions/user.actions';
import './styles.css';
import group from '../../assets/imgs/Group 31@2x.png';

class ResetPasswordScreen extends Component {
  constructor(props) {
    super(props);

    localStorage.removeItem('user');
    localStorage.removeItem('me');

    this.state = {
      loading: false,
      email: '',
      password: '',
      password_confirmation: '',
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { password, password_confirmation } = this.state;
    const { dispatch, match } = this.props;

    if (password && password_confirmation && password_confirmation == password) {
      let token = match.params.hash;

      dispatch(userActions.reset({ password, token }, this.props.history));
    }
  }

  render() {
    const { loading } = this.props;
    const { password, password_confirmation, submitted } = this.state;
    return (
      <Fragment>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column width={10} className="full-height">
              <Image fluid className="full-height" src={group} />
            </Grid.Column>
            <Grid.Column width={6} verticalAlign="middle" className="content-login">
              <Form className="form-login" onSubmit={this.handleSubmit} loading={loading}>
                <Header as="h2" floated="left">
                  Redefinir Senha
                </Header>
                <Form.Field className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                  <input
                    type="password"
                    placeholder="Digite a nova Senha"
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={this.handleChange}
                  />
                  {submitted && !password && (
                    <Label basic color="red" pointing>
                      Senha é requerido
                    </Label>
                  )}
                </Form.Field>
                <Form.Field
                  className={
                    'form-group' + (submitted && !password_confirmation ? ' has-error' : '')
                  }>
                  <input
                    type="password"
                    placeholder="Digite a confirmação de Senha"
                    className="form-control"
                    name="password_confirmation"
                    value={password_confirmation}
                    onChange={this.handleChange}
                  />
                  {submitted && (!password_confirmation || password != password_confirmation) && (
                    <Label basic color="red" pointing>
                      As senhas não conferem
                    </Label>
                  )}
                </Form.Field>
                <Grid.Column width={16} verticalAlign="middle">
                  <Button fluid className="primary" type="submit">
                    Alterar Senha
                  </Button>
                </Grid.Column>
                <Grid.Column width={16} style={{ marginTop: '10px' }} verticalAlign="middle">
                  <Button fluid type="button" onClick={() => this.props.history.push('/login')}>
                    Voltar ao Login
                  </Button>
                </Grid.Column>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { me, loading } = state.users;
  return {
    me,
    loading,
  };
}

const connectedResetPasswordScreen = connect(mapStateToProps)(ResetPasswordScreen);
export { connectedResetPasswordScreen as ResetPasswordScreen };
