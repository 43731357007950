export const ticketConstants = {
  CREATETICKET_REQUEST: 'CREATETICKET_REQUEST',
  CREATETICKET_SUCCESS: 'CREATETICKET_SUCCESS',
  CREATETICKET_FAILURE: 'CREATETICKET_FAILURE',

  CREATETICKETINTERACTION_REQUEST: 'CREATETICKETINTERACTION_REQUEST',
  CREATETICKETINTERACTION_SUCCESS: 'CREATETICKETINTERACTION_SUCCESS',
  CREATETICKETINTERACTION_FAILURE: 'CREATETICKETINTERACTION_FAILURE',

  UPDATETICKET_REQUEST: 'UPDATETICKET_REQUEST',
  UPDATETICKET_SUCCESS: 'UPDATETICKET_SUCCESS',
  UPDATETICKET_FAILURE: 'UPDATETICKET_FAILURE',

  GETALLTICKET_REQUEST: 'GETALLTICKET_REQUEST',
  GETALLTICKET_SUCCESS: 'GETALLTICKET_SUCCESS',
  GETALLTICKET_FAILURE: 'GETALLTICKET_FAILURE',

  GETALLTICKETINTERACTION_REQUEST: 'GETALLTICKETINTERACTION_REQUEST',
  GETALLTICKETINTERACTION_SUCCESS: 'GETALLTICKETINTERACTION_SUCCESS',
  GETALLTICKETINTERACTION_FAILURE: 'GETALLTICKETINTERACTION_FAILURE',

  GETTICKET_REQUEST: 'GETTICKET_REQUEST',
  GETTICKET_SUCCESS: 'GETTICKET_SUCCESS',
  GETTICKET_FAILURE: 'GETTICKET_FAILURE',

  DELETETICKET_REQUEST: 'DELETETICKET_REQUEST',
  DELETETICKET_SUCCESS: 'DELETETICKET_SUCCESS',
  DELETETICKET_FAILURE: 'DELETETICKET_FAILURE',

  GETSTATUS_REQUEST: 'GETSTATUS_REQUEST',
  GETSTATUS_SUCCESS: 'GETSTATUS_SUCCESS',
  GETSTATUS_FAILURE: 'GETSTATUS_FAILURE',

  CREATEATTACHMENT_REQUEST: 'CREATEATTACHMENT_REQUEST',
  CREATEATTACHMENT_SUCCESS: 'CREATEATTACHMENT_SUCCESS',
  CREATEATTACHMENT_FAILURE: 'CREATEATTACHMENT_FAILURE',

  DELETEATTACHMENT_REQUEST: 'DELETEATTACHMENT_REQUEST',
  DELETEATTACHMENT_SUCCESS: 'DELETEATTACHMENT_SUCCESS',
  DELETEATTACHMENT_FAILURE: 'DELETEATTACHMENT_FAILURE',
};
