import { companyConstants } from '../constants/company.constants';
import companyService from '../services/company.service';
import { alertActions } from './alert.actions';

export const companyActions = {
  getById,
};

function getById(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const company = await companyService.getById(id);

      dispatch(success(company));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request(company) {
    return { type: companyConstants.GETCOMPANY_REQUEST, company };
  }
  function success(company) {
    return { type: companyConstants.GETCOMPANY_SUCCESS, company };
  }
  function failure(error) {
    return { type: companyConstants.GETCOMPANY_FAILURE, error };
  }
}
