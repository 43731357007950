import axios from 'axios';

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  timeout: 100000,
});

client.interceptors.request.use(
  async (config) => {
    const TOKEN = await localStorage.getItem('user');

    if (TOKEN) {
      const TOKEN_ACCESS = JSON.parse(TOKEN);
      if (TOKEN_ACCESS && TOKEN_ACCESS.token) {
        config.headers.authorization = `Bearer ${TOKEN_ACCESS.token}`;
      }
    }

    return config;
  },
  (error) => {
    console.log('Falha na solicitação:');

    console.log(error);

    return Promise.reject(error);
  },
);

client.interceptors.response.use(
  (response) => response,
  (error) => {
    let res = error.response;
    if (res.status === 403) {
      return Promise.reject(new Error('Você não tem permissão para acessar essa função'));
    }

    if (
      res.status === 401 &&
      res.config &&
      !res.config.__isRetryRequest &&
      res.data.message !== 'Invalid email/password'
    ) {
      window.location.href = '/login';
    }

    return Promise.reject(error);
  },
);

export default client;
