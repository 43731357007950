import client from '../helpers/HttpClient';

class ticketService {
  async create(ticket) {
    try {
      const result = await client.post('tickets', ticket);
      return result.data;
    } catch (error) {
      const returnMensage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMensage);
    }
  }

  async uploadAttachment(id_ticket, data) {
    try {
      const result = await client.post(`tickets/${id_ticket}/attachments`, data, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });

      return result.data;
    } catch (error) {
      const returnMensage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMensage);
    }
  }

  async deleteAttachmentById(id_ticket, id) {
    try {
      const result = await client.delete(`tickets/${id_ticket}/attachments/${id}`);

      return result.data;
    } catch (error) {
      const returnMensage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMensage);
    }
  }

  async createInteraction(id, ticket) {
    try {
      const result = await client.post(`tickets/${id}/interactions`, ticket);

      return result.data;
    } catch (error) {
      const returnMensage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMensage);
    }
  }

  async getAll(params) {
    try {
      const result = await client.get('tickets', { params });

      return result.data;
    } catch (error) {
      const returnMensage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMensage);
    }
  }

  async getAllInteraction(id, filter) {
    try {
      let endpoint = `tickets/${id}/interactions?sort=desc&internal=0`;

      if (filter) {
        endpoint = `${endpoint}&${filter}`;
      }

      const result = await client.get(endpoint);

      return result.data;
    } catch (error) {
      const returnMensage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMensage);
    }
  }

  async getStatusTickets() {
    try {
      const result = await client.get('tickets/status');

      return result.data;
    } catch (error) {
      const returnMensage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMensage);
    }
  }

  async getById(id) {
    try {
      const result = await client.get(`tickets/${id}`);

      return result.data;
    } catch (error) {
      const returnMensage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMensage);
    }
  }

  async deleteById(id) {
    try {
      const result = await client.delete(`tickets/${id}`);

      return result.data;
    } catch (error) {
      const returnMensage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMensage);
    }
  }

  async update(id, obj) {
    try {
      const result = await client.put(`tickets/${id}`, obj);

      return result.data;
    } catch (error) {
      const returnMensage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMensage);
    }
  }
}

export default new ticketService();
