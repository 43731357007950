import React, { Component, Fragment } from 'react';
import { Container, Dropdown, Image, Menu } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { userActions } from '../../actions/user.actions';

import './styles.css';
import avatarPlaceholder from '../../assets/imgs/square-image.png';
import { withRouter, Redirect } from 'react-router-dom';

const NavBarChildren = ({ children }) => <Container fluid>{children}</Container>;

class MenuBand extends Component {
  state = {
    activeItem: 'tickets',
    visible: false,
  };

  componentDidMount() {
    this.setState({
      activeItem: this.props.history.location.pathname.split('/')[1],
    });
  }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name, visible: false });
    this.props.history.push(`/${name}`);
  };

  handlePusher = () => {
    const { visible } = this.state;

    if (visible) this.setState({ visible: false });
  };

  handleToggle = () => this.setState({ visible: !this.state.visible });

  logout = () => {
    const { dispatch } = this.props;

    localStorage.removeItem('user');
    localStorage.removeItem('me');
    dispatch(userActions.logout());

    window.location.href = `/login`;
  };

  render() {
    const { children, me, company } = this.props;

    if (!me) {
      return <Redirect to={'/login'} />;
    }

    const { activeItem } = this.state;

    return (
      <Fragment>
        <Menu pointing className="main-menu">
          <Menu.Item>
            <Image size="mini" src={company?.logo_url} />
          </Menu.Item>
          <Menu.Item
            icon="code"
            name="tickets"
            content="Meus Tickets"
            active={activeItem === 'tickets'}
            onClick={this.handleItemClick}
          />
          <Menu.Menu position="right">
            <Menu.Item>
              <Image src={avatarPlaceholder} avatar />
              <Dropdown text={me ? me.email : ''} loading={!(me && me.email)}>
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon="user outline"
                    text="Perfil"
                    onClick={() => this.props.history.push('/profile')}
                  />
                  <Dropdown.Divider />
                  <Dropdown.Item icon="sign-out" text="Logout" onClick={this.logout} />
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <NavBarChildren>{children}</NavBarChildren>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { users, companies } = state;
  return {
    me: users.me || null,
    company: companies.item || null,
  };
}

const connectedMenuBand = withRouter(connect(mapStateToProps)(MenuBand));
export { connectedMenuBand as MenuBand };
