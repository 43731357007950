import client from '../helpers/HttpClient';

class userService {
  async create(obj) {
    try {
      const result = await client.post('users', obj);

      return result.data;
    } catch (error) {
      const returnMensage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMensage);
    }
  }

  async forgot(obj) {
    try {
      const result = await client.post('auth/password/forgot', obj);

      return result.data;
    } catch (error) {
      let msg = (error && error.message) || error.response.statusText;

      if (error.response.status == 404) {
        msg = 'Não há usuário cadastrado com este e-mail';
      } else if (error.response.status == 500) {
        msg = 'Ocorreu um erro. Tente novamente mais tarde.';
      }

      return Promise.reject(msg);
    }
  }

  async reset(obj) {
    try {
      const result = await client.post('auth/password/reset', obj);

      return result.data;
    } catch (error) {
      let msg = (error && error.message) || error.response.statusText;

      if (error.response.status == 404) {
        msg = 'Sua senha já foi recuperada ou o link expirou. Solicite uma nova senha novamente';
      } else if (error.response.status == 500) {
        msg = 'Ocorreu um erro. Tente novamente mais tarde.';
      }

      return Promise.reject(msg);
    }
  }

  async update(id, obj) {
    try {
      const result = await client.put(`users/${id}`, obj);

      return result.data;
    } catch (error) {
      const returnMensage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMensage);
    }
  }

  async updateMe(obj) {
    try {
      const result = await client.put(`me/edit`, obj);

      return result.data;
    } catch (error) {
      let msg = (error && error.message) || error.response.statusText;
      if (error && error.response.status == 401) {
        msg = 'Senha atual incorreta';
      } else if (error && error.response.status == 422) {
        msg = 'Nova senha não confere ou muito pequena';
      }

      return Promise.reject(msg);
    }
  }

  async login(email, password) {
    try {
      const result = await client.post('auth/login/client', {
        email,
        password,
      });

      if (!result || !result.data || !result.data.token) {
        throw new Error('Falha no Login');
      } else {
        await localStorage.setItem('user', JSON.stringify(result.data));
        return result.data;
      }
    } catch (error) {
      let msg = (error && error.message) || error.response.statusText;
      if (error && error.response.status === 401) {
        msg = 'Usuário ou senha incorretos';
      }

      return Promise.reject(msg);
    }
  }

  async logout() {
    try {
      const result = await client.post('auth/logout');

      return result;
    } catch (error) {
      const returnMensage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMensage);
    }
  }

  async getRoles() {
    try {
      const result = await client.get('auth/roles');

      return result.data;
    } catch (error) {
      const returnMensage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMensage);
    }
  }

  async getUserMe() {
    try {
      const result = await client.get('me');

      localStorage.setItem('me', JSON.stringify(result.data));
      return result.data;
    } catch (error) {
      const returnMensage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMensage);
    }
  }

  async getAll() {
    try {
      const result = await client.get('users');

      return result.data;
    } catch (error) {
      const returnMensage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMensage);
    }
  }

  async getById(id) {
    try {
      const result = await client.get(`users/${id}`);

      return result.data;
    } catch (error) {
      const returnMensage = (error && error.message) || error.response.statusText;

      return Promise.reject(returnMensage);
    }
  }
}

export default new userService();
