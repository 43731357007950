import { userConstants } from '../constants/user.constants';

let stateDefault = {};

if (localStorage.getItem('me')) {
  stateDefault.me = JSON.parse(localStorage.getItem('me'));
}

export function users(state = stateDefault, action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return {};
    case userConstants.GETALLROLES_REQUEST:
    case userConstants.GETUSERME_REQUEST:
    case userConstants.UPDATEUSERME_REQUEST:
    case userConstants.FORGOTUSER_REQUEST:
    case userConstants.RESETUSER_REQUEST:
    case userConstants.GETALLUSER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.GETUSER_SUCCESS:
      return {
        ...state,
        item: action.user,
        loading: false,
      };
    case userConstants.GETUSERME_SUCCESS:
    case userConstants.UPDATEUSERME_SUCCESS:
      return {
        ...state,
        me: action.me,
        loading: false,
      };
    case userConstants.FORGOTUSER_SUCCESS:
    case userConstants.RESETUSER_SUCCESS:
      return {
        ...state,
        forgot: action.user,
        loading: false,
      };
    case userConstants.GETALLUSER_SUCCESS:
      return {
        ...state,
        ...action.users,
        loading: false,
      };
    case userConstants.GETALLROLES_SUCCESS:
      return {
        ...state,
        roles: action.roles,
        loading: false,
      };
    case userConstants.GETALLROLES_FAILURE:
    case userConstants.GETUSERME_FAILURE:
    case userConstants.UPDATEUSERME_FAILURE:
    case userConstants.GETALLUSER_FAILURE:
    case userConstants.FORGOTUSER_FAILURE:
    case userConstants.RESETUSER_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
