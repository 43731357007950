import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Dimmer, Form, Grid, Header, Image, Label, Loader } from 'semantic-ui-react';
import { userActions } from '../../actions/user.actions';
import './styles.css';
import group from '../../assets/imgs/Group 31@2x.png';

class LoginScreen extends Component {
  constructor(props) {
    super(props);

    localStorage.removeItem('user');
    localStorage.removeItem('me');

    this.state = {
      loading: true,
      email: '',
      password: '',
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  customTheme() {
    const company = this.getCompany();

    if (!company) return null;

    const content = (
      <style type="text/css">
        :root {'{'}
        --primary: {company.primary_color}; --secondary: {company.secondary_color}; --tertiary:{' '}
        {company.tertiary_color};{'}'}
      </style>
    );

    return content;
  }

  customTitle() {
    const company = this.getCompany();

    if (!company) return null;

    return <title>{company.title}</title>;
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { email, password } = this.state;
    const { dispatch } = this.props;
    if (email && password) {
      dispatch(userActions.login(email, password))
        .then(() => {
          dispatch(userActions.getUserMe()).then(() => {
            this.setState({ loading: false });
            this.props.history.push('/tickets');
          });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  }

  getCompany() {
    const data = localStorage.getItem('company');
    return data ? JSON.parse(data) : null;
  }

  render() {
    const { loggingIn } = this.props;
    const { email, password, submitted, loading } = this.state;
    return (
      <div className="fragment-content">
        {this.customTheme()}
        {this.customTitle()}
        {loggingIn && (
          <Dimmer active={loading}>
            <Loader />
          </Dimmer>
        )}
        <Grid>
          <Grid.Row columns={2} only="computer">
            <Grid.Column width={10} className="full-height">
              <Image fluid className="full-height" src={group} />
            </Grid.Column>
            <Grid.Column width={6} verticalAlign="middle" className="content-login">
              <Form className="form-login" onSubmit={this.handleSubmit}>
                <Header as="h2" floated="left">
                  Faça Login
                </Header>
                <Form.Field className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                  <input
                    type="text"
                    placeholder="Digite seu Email"
                    className="form-control"
                    name="email"
                    value={email}
                    onChange={this.handleChange}
                  />
                  {submitted && !email && (
                    <Label basic color="red" pointing>
                      Usuário é requerido
                    </Label>
                  )}
                </Form.Field>
                <Form.Field className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                  <input
                    type="password"
                    placeholder="Digite sua Senha"
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={this.handleChange}
                  />
                  {submitted && !password && (
                    <Label basic color="red" pointing>
                      Senha é requerido
                    </Label>
                  )}
                </Form.Field>
                <Grid.Column width={16} style={{ marginBottom: '10px' }} verticalAlign="middle">
                  <Link to="/recover-password">Recuperar Senha</Link>
                </Grid.Column>
                <Grid.Column width={16} verticalAlign="middle">
                  <Button fluid className="primary" type="submit">
                    Entrar
                  </Button>
                </Grid.Column>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1} only="mobile">
            <Grid.Column>
              <Image width="55%" className="logo" src="imgs/logo-icon.png" />
              <Form className="form-login" onSubmit={this.handleSubmit}>
                <Form.Field className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                  <input
                    type="text"
                    placeholder="Digite seu Email"
                    className="form-control"
                    name="email"
                    value={email}
                    onChange={this.handleChange}
                  />
                  {submitted && !email && (
                    <Label basic color="red" pointing>
                      Usuário é requerido
                    </Label>
                  )}
                </Form.Field>
                <Form.Field className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                  <input
                    type="password"
                    placeholder="Digite sua Senha"
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={this.handleChange}
                  />
                  {submitted && !password && (
                    <Label basic color="red" pointing>
                      Senha é requerido
                    </Label>
                  )}
                </Form.Field>
                <Grid.Column width={16} style={{ marginBottom: '10px' }} verticalAlign="middle">
                  <Link to="/recover-password">Recuperar Senha</Link>
                </Grid.Column>
                <Button fluid className="secondary" type="submit">
                  Entrar
                </Button>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { me } = state.users;
  return {
    me,
    loggingIn,
  };
}

const connectedLoginScreen = connect(mapStateToProps)(LoginScreen);
export { connectedLoginScreen as LoginScreen };
