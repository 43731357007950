import React from 'react';
import { Button } from 'semantic-ui-react';
import './styles.css';

const FailedMessage = () => (
  <div id="notfound">
    <div className="notfound">
      <div className="notfound-404">
        <h1>
          4<img src="imgs/emoji.png" />4
        </h1>
      </div>
      <h2>Oops! Pagina não encontrada</h2>
      <p>
        Desculpe, mas a página que você está procurando não foi encontrada, foi removida ou você não
        tem permissão para acessá-la.
      </p>
      <Button className="primary" onClick={() => this.props.history.push('/tickets')}>
        Voltar a página Inicial
      </Button>
    </div>
  </div>
);

export default FailedMessage;
