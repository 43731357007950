import { userConstants } from '../constants/user.constants';
import userService from '../services/user.service';
import { alertActions } from './alert.actions';

export const userActions = {
  editMe,
  login,
  forgot,
  reset,
  logout,
  getUserMe,
};

function forgot(obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const user = await userService.forgot(obj);

      dispatch(success(user));
      dispatch(alertActions.success('Você receberá um email para alteração de senha!', true));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error, true));
    }
  };

  function request() {
    return { type: userConstants.FORGOTUSER_REQUEST };
  }
  function success(user) {
    return { type: userConstants.FORGOTUSER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.FORGOTUSER_FAILURE, error };
  }
}

function reset(obj, history) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const user = await userService.reset(obj);

      dispatch(success(user));
      dispatch(alertActions.success('Senha redefinida. Faça o login com a nova senha.', true));
      history.push('/login');
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error, true));
    }
  };

  function request() {
    return { type: userConstants.RESETUSER_REQUEST };
  }
  function success(user) {
    return { type: userConstants.RESETUSER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.RESETUSER_FAILURE, error };
  }
}

function editMe(obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const me = await userService.updateMe(obj);

      dispatch(success(me));
      dispatch(alertActions.success('Senha alterada', true));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error, true));
    }
  };

  function request() {
    return { type: userConstants.UPDATEUSERME_REQUEST };
  }
  function success(me) {
    return { type: userConstants.UPDATEUSERME_SUCCESS, me };
  }
  function failure(error) {
    return { type: userConstants.UPDATEUSERME_FAILURE, error };
  }
}

function login(email, password) {
  return async (dispatch) => {
    dispatch(request({ email }));

    try {
      const user = await userService.login(email, password);

      dispatch(success(user));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error, true));

      throw error;
    }
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  return async (dispatch) => {
    try {
      await userService.logout();

      localStorage.removeItem('user');
      localStorage.removeItem('me');

      dispatch(success());
    } catch (error) {
      return error;
    }
  };

  function success() {
    return { type: userConstants.LOGOUT };
  }
}

function getUserMe() {
  return async (dispatch) => {
    dispatch(request());

    try {
      const me = await userService.getUserMe();

      dispatch(success(me));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: userConstants.GETUSERME_REQUEST };
  }
  function success(me) {
    return { type: userConstants.GETUSERME_SUCCESS, me };
  }
  function failure(error) {
    return { type: userConstants.GETUSERME_FAILURE, error };
  }
}
