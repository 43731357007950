import { ticketConstants } from '../constants/ticket.constants';

export function tickets(state = {}, action) {
  switch (action.type) {
    case ticketConstants.CREATETICKET_REQUEST:
    case ticketConstants.CREATETICKETINTERACTION_REQUEST:
    case ticketConstants.GETALLTICKET_REQUEST:
    case ticketConstants.GETALLTICKETINTERACTION_REQUEST:
    case ticketConstants.GETTICKET_REQUEST:
    case ticketConstants.GETSTATUS_REQUEST:
    case ticketConstants.DELETETICKET_REQUEST:
    case ticketConstants.DELETEATTACHMENT_REQUEST:
    case ticketConstants.CREATEATTACHMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ticketConstants.GETSTATUS_SUCCESS:
      return {
        ...state,
        status: action.status,
        loading: false,
      };
    case ticketConstants.CREATETICKETINTERACTION_SUCCESS:
      return {
        ...state,
        interaction: action.interaction,
        loading: false,
      };
    case ticketConstants.GETALLTICKETINTERACTION_SUCCESS:
      return {
        ...state,
        interactions: action.interactions,
        loading: false,
      };
    case ticketConstants.CREATETICKET_SUCCESS:
    case ticketConstants.GETTICKET_SUCCESS:
    case ticketConstants.DELETETICKET_SUCCESS:
    case ticketConstants.DELETEATTACHMENT_SUCCESS:
    case ticketConstants.CREATEATTACHMENT_SUCCESS:
      return {
        ...state,
        item: action.ticket,
        loading: false,
      };
    case ticketConstants.GETALLTICKET_SUCCESS:
      return {
        ...state,
        ...action.tickets,
        loading: false,
      };
    case ticketConstants.CREATETICKET_FAILURE:
    case ticketConstants.CREATETICKETINTERACTION_FAILURE:
    case ticketConstants.GETALLTICKET_FAILURE:
    case ticketConstants.GETALLTICKETINTERACTION_FAILURE:
    case ticketConstants.GETALLSTATUS_FAILURE:
    case ticketConstants.GETTICKET_FAILURE:
    case ticketConstants.DELETETICKET_FAILURE:
    case ticketConstants.DELETEATTACHMENT_FAILURE:
    case ticketConstants.CREATEATTACHMENT_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
