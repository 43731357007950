import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Grid, Header, Label } from 'semantic-ui-react';

import { ticketActions } from '../../actions/ticket.actions';
import RichTextEditor from 'react-rte';
import './styles.css';

class TicketCreateScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      description: RichTextEditor.createEmptyValue(),
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { title, description } = this.state;

    const { dispatch } = this.props;
    if (title && description.toString('html')) {
      dispatch(
        ticketActions.create({
          title,
          description: description.toString('html'),
        }),
      ).then(() => {
        this.props.history.push('/tickets');
      });
    }
  }

  render() {
    const { title, description, submitted } = this.state;
    const { loading } = this.props;

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Header as="h1">Novo Ticket</Header>
          </Grid.Column>
        </Grid>
        <Form onSubmit={this.handleSubmit}>
          <Form.Field>
            <label>Título</label>
            <input
              name="title"
              placeholder="Título do Ticket"
              value={title}
              onChange={this.handleChange}
            />
            {submitted && !title && (
              <Label basic color="red" pointing>
                Preencha o título
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label>Descrição</label>
            <RichTextEditor
              value={description}
              className="rte"
              onChange={(value) => this.handleChange(true, { name: 'description', value })}
            />
            {submitted && !description && (
              <Label basic color="red" pointing>
                Preencha a descrição
              </Label>
            )}
          </Form.Field>
          <Button primary type="submit" loading={loading}>
            Criar
          </Button>
        </Form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { tickets } = state;
  return {
    tickets,
    loading: tickets.loading,
  };
}

const connectedTicketCreateScreen = connect(mapStateToProps)(TicketCreateScreen);
export { connectedTicketCreateScreen as TicketCreateScreen };
