import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Header, Icon, Input, Loader } from 'semantic-ui-react';
import moment from 'moment';
import { DynamicTable } from '../../components/DynamicTable/DynamicTable';
import { ticketActions } from '../../actions/ticket.actions';
import './styles.css';
import _ from 'lodash';

class TicketScreen extends Component {
  state = {
    filter: {
      search: '',
      status_id: '-1',
    },
  };

  componentDidMount() {
    this.handleSearchDebounced = _.debounce(function () {
      this.getData();
    }, 500);
    this.getData();
  }

  changeFilter(statusId) {
    const { filter } = this.state;
    filter.status_id = statusId;
    this.setState({ filter }, () => this.getData());
  }

  statusColor(status) {
    const statusObject = {
      Aberto: 'green',
      Aguardando: 'purple',
      Criado: 'blue',
      Fechado: 'red',
    };

    return `${statusObject[status]} ui circular empty label`;
  }

  getData() {
    const { dispatch } = this.props;
    const { filter } = this.state;
    const params = {};
    _.forEach(filter, (value, key) => {
      params['tickets.' + key] = value === '' ? null : value;
    });

    const { page, sort } = this.state;
    params.page = page;
    params.sort = sort;

    dispatch(ticketActions.getAll(params));
  }

  handleSearch(value) {
    const { filter } = this.state;
    filter.search = value;
    this.setState({ filter });
    this.handleSearchDebounced();
  }

  render() {
    const { filter } = this.state;
    const { tickets, loading } = this.props;

    if (!tickets || !tickets.data) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Grid className="header-page">
          <Grid.Row>
            <Grid.Column className="flex" mobile={16} tablet={16} computer={16}>
              <Header as="h1">Meus Tickets</Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column mobile={16} tablet={4} computer={4}>
              <Button.Group fluid>
                <Button positive={filter.status_id == '-1'} onClick={() => this.changeFilter(-1)}>
                  Não Fechados
                </Button>
                <Button.Or text="ou" />
                <Button
                  positive={filter.status_id === null}
                  onClick={() => this.changeFilter(null)}>
                  Todos
                </Button>
              </Button.Group>
            </Grid.Column>

            <Grid.Column mobile={16} tablet={9} computer={9}>
              <Input
                fluid
                icon="search"
                value={filter.search}
                onChange={(e, data) => this.handleSearch(data.value)}
                placeholder="Procurar..."
                loading={loading}
              />
            </Grid.Column>

            <Grid.Column mobile={16} tablet={3} computer={3}>
              <Button
                primary
                onClick={() => this.props.history.push('/tickets/create')}
                icon
                labelPosition="left"
                fluid>
                <Icon name="plus" />
                Novo
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Loader active={loading} />
        {!loading && (
          <DynamicTable
            onClick={(item) => this.props.history.push(`tickets/${item.id}/show`)}
            handlePaginationChange={(page) => {
              this.setState({ page }, () => this.getData());
            }}
            header={['#', 'Titulo', 'Criado', 'Última Alteração', 'Status']}
            columns={[
              'id',
              'title',
              {
                name: 'created_at',
                format: (item) => moment(item.created_at).format('DD/MM/YYYY HH:mm'),
              },
              {
                name: 'updated_at',
                format: (item) => moment(item.updated_at).format('DD/MM/YYYY HH:mm'),
              },
              {
                name: 'status',
                format: (item) => (
                  <div role="option" className="item">
                    <div className={this.statusColor(item.status)}></div>
                    <span className="text">{item.status}</span>
                  </div>
                ),
              },
            ]}
            data={tickets.data}
            totalPages={tickets.lastPage || 1}
            page={tickets.page || 1}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { tickets } = state;
  return {
    tickets,
    loading: tickets.loading || false,
  };
}

const connectedTicketScreen = connect(mapStateToProps)(TicketScreen);
export { connectedTicketScreen as TicketScreen };
