import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Confirm,
  Divider,
  Grid,
  GridColumn,
  Header,
  Image,
  Label,
  List,
  Loader,
  Visibility,
} from 'semantic-ui-react';
import moment from 'moment';
import { ticketActions } from '../../actions/ticket.actions';
import RichTextEditor from 'react-rte';
import _ from 'lodash';
import './styles.css';

class TicketShowScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      text: '',
      interaction: RichTextEditor.createEmptyValue(),
      interactions: [],
      page: 1,
      refresh: false,
      totalPages: 1,
      lastPages: 1,
      internal: false,
      openAttachment: false,
      submitted: false,
    };
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { match, dispatch } = this.props;
    dispatch(ticketActions.getById(match.params.id)).then(() => {
      this.moreInteraction();
    });
  }

  moreInteraction() {
    const { dispatch, match } = this.props;

    if (this.state.page <= this.state.lastPages || this.state.refresh) {
      dispatch(ticketActions.getAllInteraction(match.params.id, `page=${this.state.page}`)).then(
        () => {
          const { interactions } = this.props;
          let data = this.state.interactions;

          if (this.state.page == 1) {
            data = interactions.data;
          } else {
            data = data.concat(interactions.data);
          }

          this.setState({
            page: this.state.page + 1,
            totalPages: interactions.total,
            lastPages: interactions.lastPage,
            interactions: _.orderBy(data, ['id'], ['desc']),
          });
        },
      );
    }
  }

  handleKeyPress(e) {
    const { text, interaction } = this.state;

    if (e.key === 'Enter' && e.ctrlKey && text) {
      const { match, dispatch } = this.props;

      dispatch(
        ticketActions.createInteraction(match.params.id, {
          text: interaction.toString('html'),
        }),
      ).then(() => {
        this.setState({
          refresh: true,
          text: '',
          interaction: RichTextEditor.createEmptyValue(),
          page: 1,
        });

        this.moreInteraction();
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { interaction } = this.state;
    const { dispatch, match } = this.props;

    if (interaction.toString('html')) {
      dispatch(
        ticketActions.createInteraction(match.params.id, {
          text: interaction.toString('html'),
          internal: false,
        }),
      ).then(() => {
        this.setState({
          submitted: false,
          refresh: true,
          text: '',
          interaction: RichTextEditor.createEmptyValue(),
          page: 1,
        });

        this.moreInteraction();
      });
    }
  }

  handleCancel = () => this.setState({ open: false });

  deleteShow = () => this.setState({ open: true });

  deleteById() {
    const { match, dispatch } = this.props;

    dispatch(ticketActions.deleteById(match.params.id));
    this.props.history.push('/tickets');
  }

  handleChange(e, data) {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  onChangeUpload(e) {
    const { dispatch, match } = this.props;

    const formData = new FormData();
    formData.append('files[]', e.target.files[0]);

    dispatch(ticketActions.uploadAttachment(match.params.id, formData)).then(() => {
      dispatch(ticketActions.getById(match.params.id)).then(() => {
        this.moreInteraction();
      });
    });
  }

  deleteAttachment(id) {
    const { dispatch, match } = this.props;

    dispatch(ticketActions.deleteAttachmentById(match.params.id, id)).then(() => {
      dispatch(ticketActions.getById(match.params.id)).then(() => {
        this.moreInteraction();
        this.setState({ deleteAttachmentId: null, openAttachment: false });
      });
    });
  }

  render() {
    const { tickets, loading } = this.props;
    const { open, interaction, interactions, openAttachment } = this.state;

    if (!tickets || !tickets.item || !interactions) {
      return <Loader active={loading} />;
    }

    return (
      <Fragment>
        <Confirm
          key={'ticket'}
          content="Deseja remover esse item?"
          open={open}
          onCancel={this.handleCancel}
          onConfirm={() => this.deleteById()}
        />
        <Confirm
          key={'attachment'}
          content="Deseja remover esse item?"
          open={openAttachment}
          onCancel={() => this.setState({ openAttachment: false })}
          onConfirm={() => this.deleteAttachment(this.state.deleteAttachmentId)}
        />
        <Grid className="header-page" loading={loading}>
          <Grid.Row>
            <Grid.Column computer={1} mobile={1} tablet={1}>
              <Label as="span" color="blue" style={{ fontWeight: 'bold', fontSize: '18px' }} ribbon>
                {tickets.item.id}
              </Label>
            </Grid.Column>
            <Grid.Column mobile={10} tablet={11} computer={11}>
              <Header as="h1">{tickets.item.title}</Header>
            </Grid.Column>
            <Grid.Column mobile={5} tablet={4} computer={4} textAlign="right">
              <Button
                icon="trash alternate outline"
                onClick={this.deleteShow}
                title="Excuir chamado"
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={3}>
            <Grid.Column>
              <h4>Solicitante</h4> {tickets.item.user?.name}
            </Grid.Column>
            <Grid.Column>
              <h4>Time</h4>{' '}
              {tickets.item.team && tickets.item.team_id ? tickets.item.team.name : ' - '}
            </Grid.Column>
            <Grid.Column>
              <h4>Executor</h4> {tickets.item.assignee ? tickets.item.assignee.name : ' - '}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={3}>
            <GridColumn>
              <h4>Status</h4> {tickets.item.status?.name}
            </GridColumn>
            <GridColumn>
              <h4>Criado em</h4> {moment(tickets.item.created_at).format('DD/MM/YYYY HH:mm:ss')}
            </GridColumn>
            <GridColumn>
              <h4>Última Atualização</h4>{' '}
              {moment(tickets.item.updated_at).format('DD/MM/YYYY HH:mm:ss')}
            </GridColumn>
          </Grid.Row>

          <Grid.Row columns={1}>
            <Grid.Column>
              <h4>Descrição</h4>
              <div dangerouslySetInnerHTML={{ __html: tickets.item.description }}></div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={1}>
            <Grid.Column>
              <h4>
                Anexos
                <Label style={{ marginLeft: '5px' }} circular color="green" key="green">
                  {tickets.item.attachment ? tickets.item.attachment.length : ''}
                </Label>
              </h4>
              <label className="ui button" htmlFor="file">
                Anexar arquivo
              </label>
              <input
                name="file"
                id="file"
                style={{ display: 'none' }}
                type="file"
                onChange={(e) => this.onChangeUpload(e)}
              />
              <List divided verticalAlign="middle">
                {tickets.item.attachment
                  ? tickets.item.attachment.map((attachment, key) => (
                      <List.Item key={attachment.id + key}>
                        <List.Content floated="right">
                          <Button
                            negative
                            icon="close"
                            onClick={() =>
                              this.setState({
                                deleteAttachmentId: attachment.id,
                                openAttachment: true,
                              })
                            }
                          />
                        </List.Content>
                        <Image avatar src="imgs/square-image.png" />
                        <List.Content>
                          <List.Header as="a" href={attachment.url} target="_blank">
                            {_.last(attachment.url.split('/'))}
                          </List.Header>
                          {moment(attachment.created_at).format('DD/MM/YYYY HH:mm')}
                        </List.Content>
                      </List.Item>
                    ))
                  : ''}
              </List>
            </Grid.Column>

            <Grid.Column>
              <Divider horizontal>
                Interações
                <Label style={{ marginLeft: '5px' }} circular color="green" key="green">
                  {this.state.interactions.length}
                </Label>
              </Divider>
              <div id="editor">
                <RichTextEditor
                  placeholder="Digite uma Interação"
                  value={this.state.interaction}
                  className="rte"
                  onChange={(value) => this.handleChange(true, { name: 'interaction', value })}
                />
              </div>
              <Button
                content="Enviar"
                onClick={(e) => this.handleSubmit(e)}
                labelPosition="left"
                icon="edit"
                primary
                disabled={!interaction ? true : false}
                style={{ marginTop: '10px' }}
              />
              <Visibility
                as="div"
                className="ui animated middle aligned list divided relaxed"
                continuous={false}
                once={false}
                onBottomVisible={() => this.moreInteraction()}>
                {interactions.map((interaction, key) => (
                  <List.Item key={key}>
                    <List.Content>
                      <List.Header as="a">{interaction.user}</List.Header>
                      <List.Header
                        as="span"
                        floated="right"
                        style={{ fontSize: '12px', color: 'rgba(0,0,0,.7)' }}>
                        {moment(interaction.created_at).format('DD/MM/YYYY HH:mm:ss')}
                      </List.Header>
                      <List.Description>
                        <div dangerouslySetInnerHTML={{ __html: interaction.text }}></div>
                      </List.Description>
                    </List.Content>
                  </List.Item>
                ))}
              </Visibility>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { tickets } = state;
  return {
    tickets,
    interactions: tickets.interactions || [],
    loading: tickets.loading || false,
  };
}

const connectedTicketShowScreen = connect(mapStateToProps)(TicketShowScreen);
export { connectedTicketShowScreen as TicketShowScreen };
