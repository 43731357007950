import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Grid, Header, Image, Label } from 'semantic-ui-react';
import { userActions } from '../../actions/user.actions';
import './styles.css';
import group from '../../assets/imgs/Group 31@2x.png';

class RecoverPasswordScreen extends Component {
  constructor(props) {
    super(props);

    localStorage.removeItem('user');
    localStorage.removeItem('me');

    this.state = {
      loading: false,
      email: '',
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true, loading: true });
    const { email } = this.state;
    const { dispatch } = this.props;
    if (email) {
      dispatch(userActions.forgot({ email }));
    }
  }

  render() {
    const { email, submitted } = this.state;
    const { loading } = this.props;
    return (
      <div className="fragment-content">
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column width={10} className="full-height">
              <Image fluid className="full-height" src={group} />
            </Grid.Column>
            <Grid.Column width={6} verticalAlign="middle" className="content-login">
              <Form className="form-login" onSubmit={this.handleSubmit} loading={loading}>
                <Header as="h2" floated="left">
                  Recuperação de Senha
                </Header>
                <Form.Field className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                  <input
                    type="text"
                    placeholder="Digite seu Email"
                    className="form-control"
                    name="email"
                    value={email}
                    onChange={this.handleChange}
                  />
                  {submitted && !email && (
                    <Label basic color="red" pointing>
                      E-mail é obrigatório
                    </Label>
                  )}
                </Form.Field>
                <Grid.Column width={16} verticalAlign="middle">
                  <Button fluid className="primary" type="submit">
                    Recuperar Senha
                  </Button>
                </Grid.Column>
                <Grid.Column width={16} style={{ marginTop: '10px' }} verticalAlign="middle">
                  <Button fluid type="button" onClick={() => this.props.history.push('/login')}>
                    Voltar ao Login
                  </Button>
                </Grid.Column>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { me, loading } = state.users;
  return {
    me,
    loading,
  };
}

const connectedRecoverPasswordScreen = connect(mapStateToProps)(RecoverPasswordScreen);
export { connectedRecoverPasswordScreen as RecoverPasswordScreen };
